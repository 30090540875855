<template>
  <div style="height: 100vh" class="flex items-center justify-center">
    <div>
      <h1 class="text-center">Abbonamento acquistato con successo!</h1>
      <div class="text-center">
      <q-btn color="positive" :to="{ name: 'projects' }">
        Vai ai progetti
      </q-btn>
    </div>
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>

</style>
